<template>
    <div class="bg-white flex">
    
        <router-link 
            :to="{ name: 'dashboard', params: {accountId} }"
            class="item">
            
            <i class="fas fa-chart-line" />
            <div>
                {{ $t('navigation.dashboard') }}
            </div>
        </router-link>
        
        <router-link 
            :to="{ name: 'products', params: {accountId} }"
            class="item">
            
            <i class="fas fa-boxes" />
            <div>
                {{ $t('navigation.products') }}
            </div>
        </router-link>
        
        <router-link 
            :to="{ name: 'customers', params: {accountId} }"
            class="item">
            
            <i class="fas fa-users" />
            <div>
                {{ $t('navigation.customers') }}
            </div>
        </router-link>
        
        <router-link 
            :to="{ name: 'offers', params: {accountId} }"
            class="item">
            
            <i class="fas fa-file-medical-alt" />
            <div>
                {{ $t('navigation.offers') }}
            </div>
        </router-link>
        
        <!-- <router-link 
            :to="{ name: 'learn', params: {accountId} }"
            class="item">
            
            <i class="fas fa-lightbulb" />
            <div class="">
                Learn
            </div>
        </router-link>
        -->
        
        <router-link 
            :to="{ name: 'settings', params: {accountId} }"
            class="item">
            
            <i class="fas fa-user-cog" />
            <div>
                {{ $t('navigation.settings') }}
            </div>
        </router-link>
        
        <!--
        <div 
            v-if="getUser"
            class="w-full text-center mt-8"
            @click="showNavigation = false">
            
            <div class="text-black">
                {{ getUser.attributes.email }}
            </div>
            
            <button 
                @click="signOut()"
                class="link">
                Sign Out 
            </button>
        </div>
        -->
        
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        computed: {
            getUser(){
                return this.$store.getters.getUser;
            }
        },
        
        data () {
            return {
                
            }
        },
    }
</script>

<style lang="scss" scoped>
    .item {
        @apply my-2;
        @apply py-2;
        @apply flex-1;
        @apply flex;
        @apply flex-col;
        @apply items-center;
        @apply justify-center;
        @apply overflow-hidden;
        @apply text-gray-500;
        
        i {
            @apply mb-1;
            @apply text-xl;
        }
        
        div {
            // @apply hidden;
            // font-size: .75rem;
            @apply text-xs;
            @apply mt-1;
            @apply leading-none;
        }
        
        &.router-link-active {
            // @apply bg-cyan;
            
            i {
                // @apply text-white;
                @apply text-cyan;
            }
        
            div {
                // @apply text-white;
                @apply text-cyan;
            }
        }
    }
</style>
